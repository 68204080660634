<template>
  <article class="doctor-agenda">
    <template v-if="ui.hasLoaded">
      <div class="doctor-agenda__box">
        <div
          v-if="doctor.data"
          class="doctor-agenda__doctor"
        >
          <img
            :src="doctor.data.profilePicture"
            class="doctor-agenda__doctor-img"
            :style="`background-image:url(${doctor.data.profilePicture})`"
            @error="getPlaceholderImage($event)"
          >

          <div class="flex flex-col ml-4 content-start items-start justify-start self-stretch">
            <h2 class="doctor-agenda__doctor-name">
              {{ doctor.data.displayName }}
            </h2>

            <p class="my-0 text-sm text-gray-400">
              CRM {{ doctor.data.crm }}
            </p>

            <div
              v-if="doctor.data"
              :class="{ 'mt-auto': doctor.data.medicalSpecialties.length > 0 }"
              class="my-0 font-normal text-gray-400"
            >
              <select
                v-if="doctor.data.medicalSpecialties.length > 0"
                v-model="medicalSpecialty"
                :style="`
                  background-image: url('${caret}');
                  width: ${
                  getMaxLength(doctor.data.medicalSpecialties.map((item) => item.name)) + 5 > 57
                    ? 57
                    : getMaxLength(doctor.data.medicalSpecialties.map((item) => item.name)) + 5
                }ch;
                  max-width: 57ch;
                `"
                class="doctor-specialties"
              >
                <option
                  v-for="(specialty, index) in doctor.data.medicalSpecialties"
                  :key="`select-list-${index}`"
                  :value="specialty"
                  class="doctor-specialties__item"
                >
                  {{ specialty.name | setSpecialtyName }}
                </option>
              </select>

              <span
                v-if="doctor.data.medicalSpecialties.length === 0"
                class="font-semibold block mt-1"
              >
                {{ doctor.data.medicalSpecialties[0].name | setSpecialtyName }}
              </span>
            </div>
          </div>
        </div>

        <h2
          v-if="!doctor.data"
          class="mt-2 text-gray-400 text-xl"
        >
          Médico não encontrado
        </h2>
      </div>

      <template v-if="offices.length > 0">
        <div class="appointment-date__box doctor-agenda__box !mb-2">
          <h2 class="appointment-date__title">
            Escolha o método de pagamento
          </h2>

          <div
            :class="{ 'cursor-not-allowed': ui.isLoading }"
            class="appointment-payment-method__wrapper"
          >
            <div
              v-for="(method, index) in paymentMethods"
              :key="`payment-method-${index}`"
              :aria-checked="method.name === payment.method"
              role="checkbox"
              :class="{
                'appointment-payment-method__method--checked': method.name === payment.method.name,
                'pointer-events-none': ui.isLoading,
              }"
              class="appointment-payment-method__method"
              @click="setPayment({ method })"
            >
              <icon
                :name="method.icon"
                color="gray"
                class="appointment-payment-method__method-icon"
              />

              <p class="appointment-payment-method__method-text">
                {{ method.label }}
              </p>
            </div>
          </div>
        </div>

        <template v-if="payment.method.name">
          <div class="appointment-date__box doctor-agenda__box">
            <h2 class="appointment-date__title">
              Escolha abaixo uma data
            </h2>

            <div class="appointment-date__wrapper">
              <div
                :class="{ 'appointment-date__date--disabled': ui.isLoading }"
                class="appointment-date__date"
                @click="openSafariDatepicker"
              >
                <span class="appointment-date__date-text">
                  {{ appointment.date | formattedDate }}
                </span>

                <img
                  :src="require(`@/assets/img/icons/${partner.theme}-ico-pickdate.svg`)"
                  class="appointment-date__date-icon"
                >
              </div>

              <input
                v-if="!isSafariDesktop"
                v-model="appointment.date"
                :disabled="ui.isLoading"
                :min="today"
                type="date"
                class="appointment-date__date-input"
              >

              <div
                v-show="isSafariDesktop && ui.toggleSafariDatepicker"
                class="appointment-date__safari-datepicker"
              >
                <dr-calendar v-model="appointment.dateSafari" />
              </div>
            </div>
          </div>

          <div
            v-if="appointment.date"
            class="appointment-date__time doctor-agenda__box"
          >
            <transition name="blend">
              <div
                v-if="ui.isLoading"
                :key="'loader'"
                class="appointment-date__loader"
              >
                <div class="appointment-date__loader-wrapper">
                  <p class="appointment-date__loader-text">
                    Carregando horários disponíveis, aguarde...
                  </p>

                  <progress class="appointment-date__loader-bar" />
                </div>
              </div>
            </transition>

            <transition name="fade">
              <div
                v-if="!ui.isLoading"
                class="appointment-date__time-wrapper"
              >
                <carousel
                  :navigation-enabled="true"
                  :navigation-next-label="`
                    <i class='icon icon-arrow-right appointment-date__office-icon'></i>
                  `"
                  :navigation-prev-label="`
                    <i class='icon icon-arrow-left appointment-date__office-icon'></i>
                  `"
                  :autoplay="false"
                  :per-page="1"
                  :adjustable-height="true"
                  pagination-color="var(--color-gray-200)"
                  pagination-active-color="var(--color-primary)"
                  class="appointment-date__office"
                >
                  <slide
                    v-for="(office, index) in offices"
                    :key="`presential-office-${index}`"
                    class="appointment-date__office-slide"
                  >
                    <div class="appointment-date__office-card">
                      <h4 class="appointment-date__office-name">
                        {{ office.name }}
                      </h4>

                      <p
                        v-if="office.address"
                        class="appointment-date__office-text !mb-0 !pb-0"
                      >
                        {{ getAddress(office.address) }}
                      </p>

                      <div
                        v-if="office.address"
                        class="appointment-date__office-row !mt-0 !pt-0"
                      >
                        <a
                          :href="`https://maps.google.com/maps?q=${getAddress(office.address)}`"
                          target="_blank"
                          class="appointment-date__office-link ml-auto"
                        >
                          <span class="appointment-date__office-map">
                            [ver no mapa]
                          </span>
                        </a>
                      </div>

                      <div
                        class="
                        appointment-date__office-row
                        appointment-date__office-row--center"
                      >
                        <div class="appointment-details__modality !ml-0">
                          <span
                            :class="
                              /telemedicine/gi.test(office.type)
                                ? 'appointment-card__modality-label--telemedicina'
                                : 'appointment-card__modality-label--presencial'
                            "
                            class="appointment-card__modality-label"
                          >
                            {{
                              /telemedicine/gi.test(office.type)
                                ? 'Telemedicina'
                                : 'Presencial'
                            }}
                          </span>
                        </div>

                        <p class="appointment-date__office-price">
                          {{
                            parseFloat(
                              appointment.consultationPrice
                            )
                              | toCurrency
                          }}
                        </p>
                      </div>
                    </div>

                    <template v-if="office.timetable.length === 0">
                      <p class="appointment-date__time-not-found mt-4">
                        Não foram encontrados horários disponíveis na data escolhida.
                      </p>
                    </template>

                    <template v-if="office.timetable.length > 0">
                      <list-filter
                        v-model="office.time"
                        :selected="office.time"
                        :disabled="ui.isLoading"
                        :bigger-label="true"
                        :options="office.timetable"
                        class="appointment-date__time-list"
                        @select="(time) => {
                          offices.forEach((office) => office.time = null);
                          office.time = time;
                          appointment.office = office.id;
                        }"
                      />
                    </template>
                  </slide>
                </carousel>
              </div>
            </transition>
          </div>
        </template>
      </template>

      <div class="max-w-xs w-full m-auto mt-4">
        <ui-button
          :disabled="isSubmitDisabled"
          class="mb-12"
          label="Agendar consulta"
          @click="goToPayment"
        />
      </div>
    </template>

    <transition
      name="fade"
      mode="out-in"
    >
      <loader v-if="ui.showLoader" />
    </transition>
  </article>
</template>

<script>
import {
  format,
  formatISO,
  parseISO,
  isBefore,
  addDays,
  subDays,
} from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import { mapGetters, mapActions } from 'vuex';
import { DrCalendar } from 'cm2tech-vue-calendar';
import { Carousel, Slide } from 'vue-carousel';
import placeholderImage from '@/assets/img/icons-component/Logo.svg';
import setSpecialtyName from '@/filters/specialtyName';
import toCurrency from '@/filters/convertToCurrency';
import completeStep from '@/mixins/completeStep';
import handleError from '@/mixins/handleError';
import scrollToSection from '@/mixins/scrollToSection';
import doctors from '@/api/user/myDoctors';
import doctorAgenda from '@/api/doctor/doctorAgenda';
import consultationPrice from '@/api/appointmentScheduling/consultationPrice';
import Loader from '@/components/ui/Loader.vue';
import UiButton from '@/components/ui/Button.vue';
import Icon from '@/components/ui/Icon.vue';
import ListFilter from '@/components/ui/ListFilter.vue';
import { SEARCH_APPOINTMENTS_LIMIT } from '@/data/constants';

export default {
  name: 'DoctorAgenda',

  filters: {
    formattedDate(value) {
      const args = value.split('-');

      return format(
        new Date(
          parseInt(args[0], 10),
          parseInt(args[1], 10) - 1,
          parseInt(args[2], 10),
        ),
        'iiii\',\' d \'de\' MMMM \'de\' yyyy',
        { locale: ptBr },
      ).toLowerCase();
    },
    toCurrency,
    setSpecialtyName,
  },

  mixins: [completeStep, scrollToSection, handleError],

  components: {
    Loader,
    UiButton,
    ListFilter,
    DrCalendar,
    Carousel,
    Slide,
    Icon,
  },

  data() {
    return {
      doctor: {
        api: null,
        data: null,
        id: null,
      },
      agenda: {
        api: null,
        data: null,
      },
      appointment: {
        date: null,
        time: null,
        dateSafari: null,
        office: null,
        consultationPrice: 0,
      },
      medicalSpecialty: null,
      offices: [],
      today: null,
      ui: {
        showLoader: false,
        isLoading: false,
        hasLoaded: false,
        placeholder: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
        toggleSafariDatepicker: false,
        showMedicalSpecialtiesPicker: false,
        searchedDays: 0,
      },
    };
  },

  computed: {
    ...mapGetters('user', ['headers']),
    ...mapGetters('partner', ['partner', 'paymentMethods']),
    ...mapGetters('scheduling', ['payment']),

    isSafariDesktop() {
      const agent = navigator.userAgent.toLowerCase();

      return /safari/gi.test(agent) && !/iphone/gi.test(agent);
    },

    showMedicalSpecialtiesControls() {
      if (this.doctor.data && this.doctor.data.medicalSpecialties.length > 0) {
        return true;
      }

      return false;
    },

    isSubmitDisabled() {
      if (
        this.offices
        && this.offices.some((office) => office.time)
      ) {
        return false;
      }

      return true;
    },

    caret() {
      const url = `${
        document.location.protocol
      }//${
        document.location.hostname
      }${
        document.location.port
          ? `:${document.location.port}`
          : ''
      }`;

      return `${url}/static/img/ui/${this.partner.theme}-caret-select.svg`;
    },
  },

  watch: {
    'appointment.date': {
      deep: true,
      immediate: false,
      handler(value, oldValue) {
        const [year, month, day] = value.split('-');
        const date = new Date(year, month - 1, day);

        if (isBefore(date, new Date())) {
          this.appointment.date = format(new Date(), 'yyyy-MM-dd');
        }

        if (this.appointment.date !== oldValue) {
          this.getOfficesAvailableTime();
        }
      },
    },

    'appointment.dateSafari': {
      deep: false,
      immediate: false,
      handler(next, prev) {
        if (next !== prev) {
          this.ui.toggleSafariDatepicker = false;
          const then = parseISO(next);

          if (isBefore(then, new Date())) {
            this.appointment.dateSafari = formatISO(new Date());
          }

          this.appointment.date = format(parseISO(this.appointment.dateSafari), 'yyyy-MM-dd');
        }
      },
    },

    'payment.method.name': {
      deep: false,
      immediate: false,
      handler(method) {
        if (method && this.appointment.date) {
          this.getOfficesAvailableTime();
          this.scrollToSection('.appointment-date__box.doctor-agenda__box');
        }
      },
    },
  },

  created() {
    this.resetScheduling();

    this.today = format(new Date(), 'yyyy-MM-dd');
    this.appointment.date = this.today;
    this.appointment.dateSafari = formatISO(new Date());

    this.doctor.id = parseInt(this.$route.params.doctorId, 10);

    this.doctor.api = doctors(this.headers);
    this.agenda.api = doctorAgenda(this.headers);

    this.ui.showLoader = true;
    this.toggleProgressBar();

    this.doctor.api
      .getDoctor(this.doctor.id)
      .then((data) => {
        this.doctor.data = {};
        this.doctor.data.displayName = data['display_name'];
        this.doctor.data.crm = data.crm;
        this.doctor.data.profilePicture = this.getDoctorProfilePicture(this.doctor.id);

        this.doctor.data.medicalSpecialties = data['medical_specialties'];

        this.doctor.data.medicalSpecialtiesLabel = data['medical_specialties']
          .reduce((acc, item) => {
            const specialty = item.name;
            return `${acc ? `${acc}, ` : ''}${setSpecialtyName(specialty)}`;
          }, '');

        [this.medicalSpecialty] = this.doctor.data.medicalSpecialties;
      })
      .then(() => this.getConsultationPrice())
      .then(({ price }) => {
        this.appointment.consultationPrice = price;
      })
      .then(() => this.agenda.api.fetchAllOffices(this.doctor.id))
      .then((res) => {
        this.offices = res.map((item) => ({ ...item, time: null }));
      })
      .catch((err) => {
        if (typeof err === 'string') {
          this.handleError(new Error(err));
          return;
        }

        const msg = this.findErrorMessage(err)[0];
        this.handleError(new Error(msg));
      })
      .finally(() => {
        this.ui.showLoader = false;
        this.ui.hasLoaded = true;
        this.toggleProgressBar(false);
      });
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar', 'openModalDialog', 'closeModalDialog']),
    ...mapActions('scheduling', [
      'setAppointment',
      'setOffice',
      'setDoctor',
      'setPayment',
      'setPayment',
      'resetScheduling',
    ]),

    getDoctorProfilePicture(id) {
      return `${process.env.VUE_APP_BASE_API}/s3/file/doctor/${id}/profile_picture?${Date.now()}`;
    },

    getPlaceholderImage(event) {
      event.target.src = this.ui.placeholder;
      this.doctor.profilePicture = placeholderImage;
    },

    openSafariDatepicker() {
      if (this.isSafariDesktop) {
        this.ui.toggleSafariDatepicker = !this.ui.toggleSafariDatepicker;
      }
    },

    getAddress(address = '') {
      if (address === '') {
        return null;
      }

      const {
        complement,
        district,
        number,
        street,
        cep,
        city,
        uf,
      } = address;

      const formattedCity = city.name.charAt(0).toUpperCase() + city.name.slice(1);

      return `${street} ${number} ${complement ? `- ${complement}` : ''} - ${district}
        CEP ${cep.trim().replace(/^([\d]{5})([\d]{3})?/g, '$1-$2')} - ${formattedCity}/${uf.name}`;
    },

    getOfficesAvailableTime() {
      if (this.offices.length > 0) {
        this.appointment.time = null;
        this.appointment.office = null;
        this.ui.isLoading = true;
        this.toggleProgressBar();

        const promises = [];

        this.offices.forEach((office) => {
          this.$set(office, 'timetable', []);
          this.$set(office, 'time', null);

          const availableTime = () => this.agenda.api
            .fetchOfficeSchedule(
              office.id,
              this.appointment.date,
              office.type,
            )
            .then((timetable) => {
              this.$set(office, 'timetable', timetable);
            })
            .catch(() => {
              this.$set(office, 'timetable', []);
            });

          promises.push(availableTime());
        });

        Promise.allSettled(promises)
          .then(() => {
            const checkUnavailableTime = (office) => office.timetable.length === 0;

            if (
              this.offices.every((office) => checkUnavailableTime(office))
              && this.ui.searchedDays < SEARCH_APPOINTMENTS_LIMIT
            ) {
              this.ui.searchedDays += 1;
              const [year, month, day] = this.appointment.date.split('-');
              const date = addDays(new Date(year, month - 1, day), 1);

              this.appointment.date = format(date, 'yyyy-MM-dd');
            }

            if (
              this.ui.searchedDays > 0
              && !this.offices.every((office) => checkUnavailableTime(office))
            ) {
              const [year, month, day] = this.appointment.date.split('-');
              const finalDate = new Date(year, month - 1, day);
              const initialDate = subDays(finalDate, this.ui.searchedDays);

              const msg = this.payment.method.name === 'boleto'
                ? `O dia mais próximo com horários disponíveis para pagamento
                  com boleto bancário é <b>${format(finalDate, 'dd/MM/yyyy')}</b>.`
                : `Não foram encontrados horários para o dia
                <b>${format(initialDate, 'dd/MM/yyyy')}</b>. O dia mais próximo com horários
                disponíveis é <b>${format(finalDate, 'dd/MM/yyyy')}</b>.`;

              this.openModalDialog({
                type: 'alert',
                title: 'Atenção',
                text: msg,
                confirmText: 'Fechar',
                size: 'sm',
                fn: this.closeModalDialog,
              });
            }

            this.ui.isLoading = false;
            this.toggleProgressBar(false);
          });
      }
    },

    goToPayment() {
      const doctorOffice = this.offices.find((office) => office.time);

      this.toggleProgressBar();

      this.setAppointment({
        date: this.appointment.date,
        time: doctorOffice.time.time,
        specialty: this.medicalSpecialty,
        modality: doctorOffice.type === 'FACETOFACE' ? 'presential' : 'telemedicine',
      });

      this.setDoctor({ ...this.doctor, id: this.doctor.id });

      this.setOffice({
        id: doctorOffice.id,
        uuid: doctorOffice.uuid,
      });

      this.setPayment({ price: this.appointment.consultationPrice });

      if (doctorOffice.type === 'FACETOFACE') {
        const address = this.getAddress(doctorOffice.address);
        this.setOffice({ address });
      }

      this.completeStep(1);
      this.completeStep(2);
      this.completeStep(3);

      this.toggleProgressBar(false);
    },

    getMaxLength(arr) {
      const sortByMaxLength = (a, b) => {
        if (a.length < b.length) return 1;
        if (a.length > b.length) return -1;
        return 0;
      };

      return arr.sort(sortByMaxLength)[0].length;
    },

    getConsultationPrice() {
      return new Promise((resolve, reject) => {
        const priceGetter = consultationPrice(this.headers, this.partner.id);

        priceGetter.getPrice(this.doctor.id)
          .then((res) => resolve(res))
          .catch(reject);
      });
    },

    findErrorMessage(object) {
      let errorMessage = null;

      const recursiveSearch = (object) => {
        for (const value in object) {
          if (value === 'errors') {
            errorMessage = object[value];
            break;
          } else {
            recursiveSearch(object[value]);
          }
        }
      };

      if (Array.isArray(object)) {
        object.forEach((element) => {
          if (typeof element === 'object') {
            recursiveSearch(element);
          } else {
            errorMessage = element;
          }
        });
      } else {
        recursiveSearch(object);
      }

      return errorMessage;
    },
  },
};
</script>

<style lang="scss">
.blend {
  &-enter {
    opacity: 0;
    display: none;
    transition: all 0.3s ease;

    &-active {
      opacity: 0;
    }

    &-active-to {
      opacity: 1;
      position: relative;
      transition: all 0.3s ease;
      transition-delay: 100ms;
    }
  }

  &-leave {
    display: none;
    opacity: 1;
    transition: all 0.3s ease;

    &-active {
      opacity: 0;
    }

    &-active-to {
      display: block;
      opacity: 0;
      transition: all 0.3s ease;
    }
  }
}
</style>
